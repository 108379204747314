import { bleskHeader } from '@default/scripts/modules/blesk-header';

bleskHeader();

/**
 * Modul loadMore inituje a nastavuje komponentu LoadMore, ktera zajistuje asynchronni nacteni obsahu
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se to ma aplikovat
 */
const dynamicLoadMore = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const loadMoreElements = document.querySelectorAll('[data-load-more]');

    if (loadMoreElements.length) {
        const { loadMore } = await import('./modules/load-more');

        loadMore(loadMoreElements);
    }
};

/**
 * Modul ipadChangeViewport zajistuje na iPadu upravu meta tagu viewport a pridani provizornich CSS
 *
 * Dynamicky import modulu, pokud je detekován iPad
 */
const dynamicIpadChangeViewport = async () => {
    if (document.documentElement.clientWidth <= 1024 && navigator.userAgent.match(/iPad/i)) {
        const { ipadChangeViewport } = await import('./modules/ipad-change-viewport');

        ipadChangeViewport();
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicLoadMore();
    dynamicIpadChangeViewport();
};

loadDynamicImports();
